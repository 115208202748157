import {Injectable} from "@angular/core";
import {action, observable} from "mobx-angular";
import {Observable} from "rxjs/Observable";
import {BehaviorSubject} from "rxjs";

export interface GitInfo {
    revision: string;
    branch: string;
    lastCommitTime: string;
}

export interface SystemInfo {
    gitInfo: GitInfo;
    built: string;
    started: string;
    usedCaptcha: "google" | "yandex";
    reCaptchaSiteKey: string;
    smartCaptchaSiteKey: string;
    translateEnabled: boolean;
}

@Injectable()
export class SystemInfoStore {

    private static _emptySystemInfo: SystemInfo = {
        gitInfo: null,
        built: null,
        started: null,
        usedCaptcha: "google",
        reCaptchaSiteKey: null,
        smartCaptchaSiteKey: null,
        translateEnabled: false,
    };

    @observable
    value: SystemInfo = SystemInfoStore._emptySystemInfo;

    private _loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loaded: Observable<boolean> = this._loaded.asObservable();

    constructor() {
    }

    @action
    set(systemInfo: SystemInfo) {
        this.value = systemInfo;
        this._loaded.next(true);
    }

    @action
    clear() {
        this._loaded.next(false);
        this.value = SystemInfoStore._emptySystemInfo;
    }
}
