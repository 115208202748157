import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Links} from "../../../links";
import {Observable} from "rxjs/Observable";
import {GrowlMessageService} from "../services/growl-message.service";
import {Admin} from "../../support/domain/admin.domain";
import {Tag} from "../../support/domain/tag.domain";
import {AdminBuilder} from "../../support/domain/builder/admin.builder";
import {ReCaptchaV3Service} from "ngx-captcha";
import {Store} from "../../support/store/store";
import {FormGroup} from "@angular/forms";

export interface UserInfo {
    user: Admin,
    panels: string[],
    error?: { code: string, message: string },
    loading: boolean,
    adminsInPanels: any;
    predefinedRepliesInPanels: any;
    tagsInPanels: any;
}

@Injectable()
export class LoginDataservice {

    private static _emptyUserInfo: UserInfo = {
        user: null,
        panels: [],
        loading: true,
        adminsInPanels: [],
        predefinedRepliesInPanels: [],
        tagsInPanels: []
    };

    private _processLogin: boolean = false;
    private _userInfo: BehaviorSubject<UserInfo>;
    userInfo: Observable<UserInfo>;

    constructor(private http: HttpClient,
                private growlMessageService: GrowlMessageService,
                private reCaptchaV3Service: ReCaptchaV3Service,
                private store: Store,
                private adminBuilder: AdminBuilder) {
        this._userInfo = <BehaviorSubject<UserInfo>>new BehaviorSubject(LoginDataservice._emptyUserInfo);
        this.userInfo = this._userInfo.asObservable();
    }

    loadUserInfo() {
        this._userInfo.next(LoginDataservice._emptyUserInfo);
        return this.http.post(Links.info, {}).filter((resp: Response) => {
            if (resp["error"]) {
                console.log("Could not load user info. " + resp["error"].message);
                return false;
            }
            return true;
        }).map((resp: Response) => {
            return {
                user: new Admin(resp["id"], resp["username"], resp["userRealName"]),
                panels: resp["panels"],
                loading: false,
                predefinedRepliesInPanels: resp["predefinedRepliesInPanels"],
                adminsInPanels: this.defineAdminsInPanels(resp["adminsInPanels"], resp["panels"]),
                tagsInPanels: this.defineTagsInPanels(resp["tagsInPanels"], resp["panels"])
            };
        }).subscribe(data => {
            this._userInfo.next(data);
        }, error => {
            console.log("Could not load user info");
            console.log(error);
        });
    }

    private defineAdminsInPanels(adminsInPanels, panels) {
        if (!adminsInPanels || !panels) {
            return null;
        }

        let result = [];

        for (let panel of panels) {
            let admins: Admin[] = [];
            admins.push(this.adminBuilder.build({
                id: -10,
                username: "me",
                userRealName: "Назначить на себя"
            }));
            admins.push(new Admin(-1, "", "Не назначен"));
            for (let adminData of adminsInPanels[panel]) {
                admins.push(this.adminBuilder.build(adminData))
            }
            adminsInPanels[panel] = admins
        }

        return adminsInPanels;
    }

    private defineTagsInPanels(tagsInPanels, panels) {
        for (let panel of panels) {
            let tags: Tag[] = [];
            tags.push(new Tag({label: "Без тега"}));
            for (let tag of tagsInPanels[panel]) {
                tags.push(new Tag(tag));
            }
            tagsInPanels[panel] = tags;
        }
        return tagsInPanels;
    }

    login(form: FormGroup) {
        this._processLogin = true;
        this._userInfo.next(LoginDataservice._emptyUserInfo);
        return this.http.post(Links.login, form.getRawValue()).filter((resp: Response) => {
            if (resp["captchaFail"]) {
                this.growlMessageService.error("что-то пошло не так");
                return false;
            }
            if (resp["error"]) {
                this.growlMessageService.error(resp["error"].message);
                return false;
            }
            return true;
        }).map((resp: Response) => {
            return LoginDataservice._emptyUserInfo;
        }).subscribe(data => {
            this._userInfo.next(data);
            this.loadUserInfo();
            this._processLogin = false;
        }, error => {
            console.log(error);
            let data = error.error;
            if (data.error.message) {
                this.growlMessageService.error(data.error.message);
            } else {
                this.growlMessageService.error("Ошибка на сервере");
            }
            this._processLogin = false;
        });
    }

    logout() {
        this._userInfo.next(LoginDataservice._emptyUserInfo);
        return this.http.post(Links.logout, {}).filter((resp: Response) => {
            if (resp["error"]) {
                this.growlMessageService.error(resp["error"].message);
                return false;
            }
            return true;
        }).map((resp: Response) => {
            return LoginDataservice._emptyUserInfo;
        }).subscribe(data => {
            this._userInfo.next(data);
            this.loadUserInfo();
        }, error => {
            console.log(error);
            this.growlMessageService.error("Could not logout. " + error.message);
        });
    }

    isProcessLogin() {
        return this._processLogin;
    }

}
