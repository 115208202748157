<div style="z-index: 999">
  <p-growl [value]="msgs" [sticky]="true" (onClose)="removeGrowlMsg($event)"></p-growl>
</div>

<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-5">
        <div class="card-group mb-0">
          <div class="card p-2">
            <form [formGroup]="loginForm" (ngSubmit)="submit()">
              <div class="card-block">
                <h1>Вход</h1>
                <p class="text-muted">Вход в аккаунт</p>
                <div class="input-group mb-1">
                  <span class="input-group-addon"><i class="icon-user"></i></span>
                  <input autofocus type="text" class="form-control" placeholder="Логин" formControlName="username">
                </div>
                <div class="input-group mb-2">
                  <span class="input-group-addon"><i class="icon-lock"></i></span>
                  <input type="password" class="form-control" placeholder="Пароль" formControlName="password">
                </div>
                <div *ngIf="store.systemInfo.usedCaptcha === 'yandex' && store.systemInfo.smartCaptchaSiteKey"
                  id="captcha-container"
                  class="smart-captcha input-group mb-2"
                  [attr.data-sitekey]="store.systemInfo.smartCaptchaSiteKey"
                  data-callback="captchaCallback"
                ></div>
                <ngx-recaptcha2 *ngIf="store.systemInfo.usedCaptcha === 'google' && store.systemInfo.reCaptchaSiteKey" 
                                class="input-group mb-2"
                                siteKey="{{store.systemInfo.reCaptchaSiteKey}}" 
                                formControlName="token">
                </ngx-recaptcha2>
                <div class="row">
                  <div class="col-12 text-right">
                    <button type="submit" class="btn btn-primary px-2" [disabled]="!loginForm.valid || isProcessLogin()">
                      {{isProcessLogin() ? 'Вход...': 'Войти'}}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
